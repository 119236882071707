import {
  FacilityRentalAvailableStatus,
  type FacilityRentalCheckCondition,
  type Profile,
  type UserCommonInfo,
  type SigninPayload,
  useFacilityStore,
} from '@store'
import {
  APPLY_CREATOR_AVAILABLE_STATE_CODE,
  APPLY_CREATOR_STATE_CODE,
  ROUTES,
} from '@configs'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()
  const mypageStore = useMyPageStore()
  const facilityStore = useFacilityStore()
  const { cookieNames, getCookie } = useCookies()
  let userInfoInCookie: SigninPayload | null = null
  let userCommonInfo: UserCommonInfo | null = null
  let profile: Profile | null = null
  let conditions: Array<FacilityRentalCheckCondition> | null | undefined = null
  // 1. 사용자 조건을 파악하기 위해 쿠키 접근(SERVER, CLIENT)
  if (process.server) {
    const { cookie } = useRequestHeaders(['cookie'])
    userInfoInCookie = getCookie<SigninPayload>(
      cookieNames.USER_INFO,
      cookie || ''
    )
  } else {
    userInfoInCookie = getCookie<SigninPayload>(cookieNames.USER_INFO)
  }
  // 2. 쿠키에 로그인 정보가 존재 한다면 사용자 기본 정보(userCommonInfo) 및 프로필(profile) 조회
  // 시설물 임대 페이지들 중 로그인이 필수 혹은 필수가 아닌 페이지가 존재하여 따로 에러 헨들링 하지 않고 상태값으로 랜딩 조건 지정
  if (userInfoInCookie) {
    const { data: _userCommonInfo } = await useAsyncData('userCommonInfo', () =>
      userStore.fetchUserCommonInfo({
        userCmmnSn: userInfoInCookie!.userCmmnSn,
      })
    )
    userCommonInfo = _userCommonInfo.value
    const { data: _profile } = await useAsyncData('profile', () =>
      mypageStore.fetchProfile()
    )
    profile = _profile.value
  }
  // 예외처리 -> 비 로그인 상태에서 라우트가드 무한 동작 방지용 조건문
  if (
    (from.fullPath === ROUTES.APPLY_RENT.path && !profile) ||
    (to.fullPath === ROUTES.APPLY_RENT.path && !profile)
  ) {
    return
  }

  // 3. 시설물 임대 조건 정보 조회(임대 신청 페이지)
  const { data: _conditions } = await useAsyncData('facilities', () =>
    facilityStore.fetchFacilityRentalCheckConditions()
  )
  conditions = _conditions.value || []
  // 4. 크리에이터 신청 컨디션 체크
  const getApplyCreatorStatus = () => {
    if (!profile) return APPLY_CREATOR_AVAILABLE_STATE_CODE.REQUIRE_SIGNIN
    if (profile.cmtyUserSeCode === APPLY_CREATOR_STATE_CODE.IS_CREATOR)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.APPROVED

    /* 요청에 따라 반복되는 상황 체크를 수정 */
    const isRejectedAndAvailable =
      profile.creatorApplicationAvailableAt === 'Y' &&
      profile.crtfcProgrsSttusCode === APPLY_CREATOR_STATE_CODE.REJECT

    if (isRejectedAndAvailable)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.AVAILABLE

    /* 추후 상태 코드를 추가해야 하는 경우에 대비하여 메서드를 추가 */
    const isUnderReview = APPLY_CREATOR_STATE_CODE.IN_REVIEW.includes(
      profile.crtfcProgrsSttusCode || ''
    )
    const isApproved = APPLY_CREATOR_STATE_CODE.APPROVED.includes(
      profile.crtfcProgrsSttusCode || ''
    )

    if (isUnderReview) return APPLY_CREATOR_AVAILABLE_STATE_CODE.UNDER_REVIEW
    if (isApproved) return APPLY_CREATOR_AVAILABLE_STATE_CODE.APPROVED
    if (profile.crtfcProgrsSttusCode === APPLY_CREATOR_STATE_CODE.REJECT)
      return APPLY_CREATOR_AVAILABLE_STATE_CODE.REJECT
    return APPLY_CREATOR_AVAILABLE_STATE_CODE.AVAILABLE
  }
  // 4-1. 입주 신청 상태 값 체크
  const rentalAvailableStatus = () => {
    // 1. 입주 신청 필수 정보 존재 여부 판단
    if (conditions.length < 1) {
      return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
    }
    // 2. 로그인 여부 판단
    if (!profile)
      return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
    if (getApplyCreatorStatus() === 'requireSignin')
      return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN
    // 3. KYC 인증 여부 판단
    if (userCommonInfo?.kycAt !== 'Y')
      return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_KYC_AUTH

    // 4. 이전 입주 신청 이력이 있다면 조회 시점 기준 (입주 신청일 ~ 20일), (입주 신청일 ~ 60일) 여부 판단
    for (const condition of conditions) {
      if (condition.applicationUnavailableCode === 'LESS_THAN_20') {
        return FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_20_DAYS
      } else if (condition.applicationUnavailableCode === 'LESS_THAN_60') {
        return FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_60_DAYS
      }
    }
    // 5. 입주 신청을 크리에이터 신청과 함께 가능 여부 판단
    if (getApplyCreatorStatus() === 'available') {
      return FacilityRentalAvailableStatus.AVAILABLE_WITH_APPLY_CREATOR
    }

    // 6. 입주 신청을 크리에이터 신청 없이 가능 여부 판단
    if (
      getApplyCreatorStatus() === 'approved' ||
      getApplyCreatorStatus() === 'underReview' ||
      getApplyCreatorStatus() === 'reject'
    ) {
      return FacilityRentalAvailableStatus.AVAILABLE_WITH_ALREADY_CREATOR
    }
    return FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS
  }
  // 예외처리 -> 입력 폼 작성 안하고 additional 진입 한 경우
  // 입주 신청 최초 페이지로 이동
  if (
    rentalAvailableStatus() ===
      FacilityRentalAvailableStatus.AVAILABLE_WITH_APPLY_CREATOR ||
    rentalAvailableStatus() ===
      FacilityRentalAvailableStatus.AVAILABLE_WITH_ALREADY_CREATOR
  ) {
    if (
      !facilityStore.rentApplyForm.applcntActPlanCn ||
      !facilityStore.rentApplyForm.applcntAdres ||
      !facilityStore.rentApplyForm.applcntCttpc ||
      !facilityStore.rentApplyForm.applcntIntrcnCn ||
      !facilityStore.rentApplyForm.applcntNm ||
      !facilityStore.rentApplyForm.privcyColctPrcuseAgreAt ||
      !facilityStore.rentApplyForm.privcyThptyProvdAgreAt
    ) {
      await useNavigations({ url: ROUTES.APPLY_RENT.path, type: 'replace' })
    }
  }
  // 5. 완료 페이지로 이동 할때 id replace -> id 값을 찾아 줘야 함!
  // -> 서버에서 받아온 값 중에 fcltrntReqstRegistDt가 있는 값을 사용
  const usedFacilityData =
    conditions?.find((item) => item.fcltrntReqstRegistDt) || null
  // 6. FacilityRentalAvailableStatus 따라 라우트 이동
  switch (rentalAvailableStatus()) {
    case FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_CONDITIONS:
    case FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_SIGN_IN:
    case FacilityRentalAvailableStatus.UNAVAILABLE_REQUIRE_KYC_AUTH:
    case FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_60_DAYS:
      await useNavigations({ url: ROUTES.APPLY_RENT.path, type: 'replace' })
      break
    case FacilityRentalAvailableStatus.UNAVAILABLE_APPLIED_LESS_THAN_20_DAYS:
      await useNavigations({
        url: useRoutePathIdChange(ROUTES.APPLY_RENT_FACILITY_COMPLETE.path, {
          id: String(usedFacilityData?.fcltrntFcltsTyCode),
        }),
        type: 'replace',
      })
      break
    case FacilityRentalAvailableStatus.AVAILABLE_WITH_APPLY_CREATOR:
    case FacilityRentalAvailableStatus.AVAILABLE_WITH_ALREADY_CREATOR:
      break
    default:
      throw showError({
        statusCode: 500,
        statusMessage: 'check conditions error in middleware rent guard',
      })
  }
})
